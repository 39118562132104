import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Modal from 'react-modal';

const EditUserProfile = ({
  openProfileEdit,
  setOpenProfileEdit,
  userId,
  user,
  setUser,
  userProfileData,
  setUserProfileData,
  token,
}) => {
  const [charCount, setCharCount] = useState(0);
  const CHARACTER_LIMIT = 200;

  const [description, setDescription] = useState(userProfileData.description);
  const [profileImage, setProfileImage] = useState(null);
  const [profileBanner, setProfileBanner] = useState(null);
  const [name, setName] = useState(userProfileData.name);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const [bannerCrop, setBannerCrop] = useState({ x: 0, y: 0 });
  const [bannerZoom, setBannerZoom] = useState(1);
  const [bannerCroppedArea, setBannerCroppedArea] = useState(null);
  const [bannerModalIsOpen, setBannerModalIsOpen] = useState(false);
  const [bannerModalImage, setBannerModalImage] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= CHARACTER_LIMIT) {
      setDescription(value);
      setCharCount(value.length);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const onBannerCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setBannerCroppedArea(croppedAreaPixels);
  }, []);

  const makeCroppedImage = async () => {
    if (modalImage && croppedArea) {
      const croppedImageUrl = await getCroppedImg(modalImage, croppedArea);
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const file = new File([blob], 'profileImage.jpg', { type: 'image/jpeg' });
      setProfileImage(file);
      setModalIsOpen(false);
    }
  };

  const makeCroppedBanner = async () => {
    if (bannerModalImage && bannerCroppedArea) {
      const croppedBannerUrl = await getCroppedImg(bannerModalImage, bannerCroppedArea);
      const response = await fetch(croppedBannerUrl);
      const blob = await response.blob();
      const file = new File([blob], 'profileBanner.jpg', { type: 'image/jpeg' });
      setProfileBanner(file);
      setBannerModalIsOpen(false);
    }
  };

  const getCroppedImg = (imageSrc, crop) => {
    const image = new Image();
    image.src = imageSrc;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        const fileUrl = window.URL.createObjectURL(blob);
        resolve(fileUrl);
      }, 'image/jpeg');
    });
  };

  const postProfile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('description', description);
    formData.append('profileImage', profileImage);
    formData.append('profileBanner', profileBanner);
    formData.append('name', name);
    formData.append('user_id', userId);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/slugcity/p/changeuserprofile`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const data = await response.json();
    if (data) {
      setUserProfileData((prev) => ({ ...prev, ...data }));
      setOpenProfileEdit(false);
    }
  };

  return (
    <div className='flex modal-background justify-center w-full z-30 h-screen bg-white/20 backdrop-blur-sm fixed'>
      <form onClick={(e) => e.stopPropagation()} className='flex relative flex-col xl:w-[670px] w-full xl:h-[90%] h-[90%] xl:p-10 p-6 m-3 bg-headerColor rounded-xl'>
        <button onClick={() => setOpenProfileEdit(!openProfileEdit)} className='rounded-full bg-gray-600 absolute top-2 w-8 h-8 right-2'>X</button>
        <input value={name? name : userProfileData.username} name='name' onChange={(e) => setName(e.target.value)} type='text' placeholder='display name' />
        {profileBanner ? (
          <img src={URL.createObjectURL(profileBanner)} alt='' className='w-full xl:h-[200px] h-[150px]' />
        ) : (
          <img src={`${process.env.REACT_APP_API_URL}/images/${userProfileData && userProfileData.banner}`} alt='pfp' className='my-2 xl:h-[200px] h-[130px] object-cover object-top' />
        )}
        <input
          name='profileBanner'
          onChange={(e) => {
            setBannerModalIsOpen(true);
            setBannerModalImage(URL.createObjectURL(e.target.files[0]));
          }}
          type='file'
          className='flex items-center h-8 p-0'
        />
        {profileImage ? (
          <img src={URL.createObjectURL(profileImage)} alt='' className='w-20 h-20 rounded-full' />
        ) : (
          <img src={`${process.env.REACT_APP_API_URL}/images/${userProfileData && userProfileData.avater}`} alt='pfp' className='w-20 h-20 rounded-full my-2' />
        )}
        <input
          name='profileImage'
          onChange={(e) => {
            setModalIsOpen(true);
            setModalImage(URL.createObjectURL(e.target.files[0]));
          }}
          type='file'
          className='flex items-center h-8 p-0'
        />
        <textarea onChange={handleInputChange} value={description? description : " "} className='xl:w-full w-[100%] p-2' name='description' cols='30' rows='6'></textarea>
        <div className='xl:w-[550px] w-[100%] flex justify-end'>{charCount}/{CHARACTER_LIMIT}</div>
        <button onClick={postProfile} className='w-[140px] h-[50px ] rounded-sm bg-elementColor font-medium text-base text-white px-4 py-2 mt-3'>save</button>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel='Crop Image'
        className='modal'
        overlayClassName='overlay'
      >
        {modalImage && (
          <div className='crop-container'>
            <Cropper
              image={modalImage}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        )}
        <button onClick={makeCroppedImage}>Crop</button>
        <button onClick={() => setModalIsOpen(false)}>Cancel</button>
      </Modal>

      <Modal
        isOpen={bannerModalIsOpen}
        onRequestClose={() => setBannerModalIsOpen(false)}
        contentLabel='Crop Banner'
        className='modal'
        overlayClassName='overlay'
      >
        {bannerModalImage && (
          <div className='crop-container'>
            <Cropper
              image={bannerModalImage}
              crop={bannerCrop}
              zoom={bannerZoom}
              aspect={16 / 9}
              onCropChange={setBannerCrop}
              onCropComplete={onBannerCropComplete}
              onZoomChange={setBannerZoom}
            />
          </div>
        )}
        <button onClick={makeCroppedBanner}>Crop </button>
        <button onClick={() => setBannerModalIsOpen(false)}> Cancel</button>
      </Modal>

      <style jsx global>{`
        .modal {
          width: 50%;
          background-color: #222222;
          position: absolute;
          top: 50%;
          left: 50%;
          right: auto;
          bottom: auto;
          margin-right: -50%;
          transform: translate(-50%, -50%);
          padding: 20px;
          border-radius: 10px;
          z-index: 1000;
        }
        .overlay {
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.75);
          z-index: 999;
        }
        .crop-container {
          position: relative;
          width: 100%;
          height: 400px;
          background: #333;
        }
      `}</style>
    </div>
  );
};

export default EditUserProfile;