import React, { useEffect } from 'react';
import PostCard from '../PostCard/PostCard';
import { useParams } from 'react-router-dom';

const Search = ({ searchHashtagPost, handleSearch }) => {
  const { hashtag_name } = useParams();

  useEffect(() => {
    if (hashtag_name) {
      handleSearch(hashtag_name);
    }
  }, [hashtag_name]);

  return (
    <div className='w-full h-full border-customBorderColor border-l-[1px] border-r-[1px]'>
      {searchHashtagPost && searchHashtagPost.length > 0 ? (
        searchHashtagPost.map((blog) => (
          <PostCard key={blog.id} blog={blog} />
        ))
      ) : (
        <div className='flex justify-center items-center h-screen text-2xl'>
          No Post Found
        </div>
      )}
    </div>
  );
};

export default Search;