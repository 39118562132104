import React from 'react';
import PostCard from '../PostCard/PostCard';

const Feeds = ({
  reload,
  userId,
  user,
  deletePost,
  openDeleteMsg,
  setOpenDeleteMsg,
  loggedIn,
  logoutUser,
  likeData,
  addBlogLike,
  deleteBlogLike,
  setOpenCommentsPost,
  bottomRef,
  loading,
  fetchPopularHashtags,
  hashtags,
  followingBlogs,
  repostUserBlog,
  deleteRepost,
  reposts
}) => {
  return (
    <div className='w-full flex justify-center min-h-screen'>
      <section className='xl:w-full w-full border-l-[1px] border-r-[1px] border-customBorderColor min-h-full'>
        {followingBlogs && followingBlogs.map((blog, index) => (
          <PostCard
            key={blog.id}
            blog={blog}
            userId={userId}
            deletePost={deletePost}
            openDeleteMsg={openDeleteMsg}
            setOpenDeleteMsg={setOpenDeleteMsg}
            likeData={likeData}
            addBlogLike={addBlogLike}
            deleteBlogLike={deleteBlogLike}
            setOpenCommentsPost={setOpenCommentsPost}
            loggedIn={loggedIn}
            repostUserBlog={repostUserBlog}
            deleteRepost={deleteRepost}
            reposts={reposts}
          />
        ))}
        {loading && <div>Loading...</div>}
        <div ref={bottomRef} style={{ height: '1px' }}></div>
      </section>
    </div>
  );
};

export default Feeds;