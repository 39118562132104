import React, { useState } from 'react';

const AddUserPostForm = ({ openUserPostForm, setOpenUserPostForm, postUserBlog, setUserPost, userPost }) => {
  const [charCount, setCharCount] = useState(0);
  const CHARACTER_LIMIT = 500;

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= CHARACTER_LIMIT) {
      setUserPost(value);
      setCharCount(value.length);
    }
  };

  return (
    <div className='flex items-center justify-center w-full z-40 h-screen modal-background bg-white/20 backdrop-blur-sm fixed'>
      
      <form onClick={(e) => e.stopPropagation()} className='flex relative flex-col items-center justify-center xl:w-[650px] w-full h-[360px]  xl:p-10 px-3 bg-headerColor rounded-xl' action="">
      <button onClick={() => setOpenUserPostForm(!openUserPostForm)} className='rounded-full bg-gray-600 absolute top-2 w-8 h-8 right-2'>X</button>
        <textarea
          onChange={handleInputChange}
          value={userPost}
          className='xl:w-[550px] w-[100%]   p-2'
          name=""
          id=""
          cols="30"
          rows="6"
        ></textarea>
        <div className=' xl:w-[550px] w-[100%] flex justify-end'>{charCount}/{CHARACTER_LIMIT}</div>
        <button onClick={postUserBlog} className='w-[200px] rounded-sm bg-elementColor font-semibold text-lg text-white px-4 py-2 mt-3'>Post</button>
      </form>
    </div>
  );
};

export default AddUserPostForm;