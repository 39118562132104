import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { IoIosHeartEmpty, IoMdHeart } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
import { VscComment } from "react-icons/vsc";
import Navigation from "../Navigation/Navigation";
import Trending from "../Navigation/Trending";
import { MdDelete } from "react-icons/md";
import Comments from "./Comments";
const CommentsDetail = ({
  openCommentsPost,
  childComment,
  comment,
  fetchComment,
  fetchChildComment,
  setOpenCommentsPost,
  reload,
  loggedIn,
  userId,
  logoutUser,
  openCommentDeleteMsg,
  setOpenCommentDeleteMsg,
  likeData,
  addCommentLike,
  deleteCommentLike,
}) => {
  const { comment_id } = useParams();

  const isLiked = likeData && likeData.find((like) => like.comment_id === comment.comment_id );

  //-------- Scroll to top when component mounts -------------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //---------- GOING BACK ARROW FUNCTION -----------
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  //--------- FETCH COMMENT -------------
  useEffect(() => {
    fetchComment(comment_id);
  }, [comment_id]);

  //--------- FETCH CHILD COMMENT -------------
  useEffect(() => {
    fetchChildComment(comment_id);
  }, [comment_id, reload]);

  return (
    <div className="w-full  min-h-screen flex justify-center">
     
      <section className="flex flex-col w-full xl:w-[100%] min-h-screen   border-l-[1px] border-r-[1px]  border-customBorderColor">
        <div className="flex items-center">
          {" "}
          <FaArrowLeft
            className="text-4xl cursor-pointer my-2 mx-2 rounded-full hover:bg-white/10 p-2"
            onClick={handleBackClick}
          />{" "}
          back
        </div>
        <div
          key={comment.id}
          className="flex gap-4 w-[100%] border-b-[1px] border-customBorderColor  p-4 "
        >
          <section className="w-full">
            <div className="w-full flex items-center gap-1 mb-4">
            <section className=' w-14 h-14 '>
        <img src={`${process.env.REACT_APP_API_URL}/images/${comment && comment.avater}`} className='bg-neutral-950 rounded-full w-12 h-12 object-cover'/>
      </section>
              <p className=" font-semibold flex justify-between">
                <Link
                  to={`/${comment && comment.username}`}
                  className="hover:text-elementColor "
                >
                  {comment && comment.username}
                </Link>
              </p>
              <p className="text-sm text-secTextColor">
                {moment(comment && comment.created_at).fromNow()}
              </p>
              {comment.user_id === userId && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                          setOpenCommentDeleteMsg({
                            status: true,
                            id: comment.comment_id,
                          });
                        }}
                        className="text-elementColor text-lg p-1 w-fit border border-customBorderColor flex z-30 hover:bg-black/20"
                      >
                        <MdDelete />
                      </button>
                    )}
                
            </div>
            <div className="my-2 px-2 text-base text-secTextColor whitespace-pre-wrap">
              {comment && comment.content}
            </div>
            {loggedIn ? (
                    
            <div className="my-2 px-2 text-xl flex gap-2">
            <div
              onClick={() =>
                setOpenCommentsPost((prevState) => ({
                  ...prevState,
                  open: !prevState.open,
                  commentType: "parent_comment_id",
                  id: comment.comment_id,
                }))
              }
            >
              {" "}
              <VscComment />
            </div>
            <div className='flex gap-1 items-center'>
          {isLiked ?
            <span onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // Prevent the click event from bubbling up to the Link
              deleteCommentLike(e, comment.comment_id);
            }} className='text-elementColor'><IoMdHeart />
            </span>
            :
            <span onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // Prevent the click event from bubbling up to the Link
              addCommentLike(e, comment.comment_id);
            }}><IoIosHeartEmpty /></span>
          }
          <span>{comment.like_count}</span>
        </div>
          </div>
            ) : (
                
            <div className="my-2 px-2 text-xl flex gap-2">
            <div
              
            >
              {" "}
              <VscComment />
            </div>
            <div className='flex gap-1 items-center'>
          {isLiked ?
            <span onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // Prevent the click event from bubbling up to the Link
              
            }} className='text-elementColor'><IoMdHeart />
            </span>
            :
            <span onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); // Prevent the click event from bubbling up to the Link
             
            }}><IoIosHeartEmpty /></span>
          }
          <span>{comment.like_count}</span>
        </div>
          </div>
                    )}
          </section>
        </div>

        <section className="w-full">
          {childComment &&
            childComment.map((child, index) => (
              <Link to={`/comments/${child.comment_id}`}>
                <Comments
                  key={index}
                  comment={child}
                  openCommentsPost={openCommentsPost}
                  setOpenCommentsPost={setOpenCommentsPost}
                  loggedIn={loggedIn}
                  userId={userId}
                  logoutUser={logoutUser}
                  openCommentDeleteMsg={openCommentDeleteMsg}
                  setOpenCommentDeleteMsg={setOpenCommentDeleteMsg}
                  likeData={likeData}
                  addCommentLike={addCommentLike}
                  deleteCommentLike={deleteCommentLike}
                  
                />
              </Link>
            ))}
        </section>
      </section>
      
    </div>
  );
};

export default CommentsDetail;
