import React, { useEffect, useState } from "react";
import DOMPurify from 'dompurify';
import { useParams, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import { IoIosHeartEmpty } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import { VscComment } from "react-icons/vsc";
import { FaArrowLeft } from "react-icons/fa";
import Comments from "../Comments/Comments";
import Navigation from '../Navigation/Navigation';
import Trending from '../Navigation/Trending';
import { MdDelete } from "react-icons/md";
import io from 'socket.io-client';
const socket = io(process.env.REACT_APP_API_URL);

const UserBlogDetail = ({
  openCommentDeleteMsg,
  setOpenCommentDeleteMsg,
  setOpenCommentsPost,
  fetchBlogComments,
  commentData,
  postComment,
  userId,
  deleteComment,
  deleteBlogLike,
  addBlogLike,
  likeData,
  reload,
  loggedIn,
  logoutUser,
  setOpenDeleteMsg,
  deleteCommentLike,
  addCommentLike,
  userBlogDetail,
  setUserBlogDetail,
  fetchUserBlogDetail
}) => {
  const { blog_id } = useParams();
  
  const [metaData, setMetaData] = useState(null);

  //-------- Scroll to top when component mounts -------------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //---------- FETCH DETAIL BLOG -----------------
  useEffect(() => {

    fetchUserBlogDetail(blog_id);
  }, [blog_id]);

  //---------- GOING BACK ARROW FUNCTION -----------
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  //------------ META CARD DATA -------------
  const extractUrls = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text ? text.match(urlRegex) || [] : [];
  };

  const extractHashtags = (text) => {
    const hashtagRegex = /#(\w+)/g;
    return text ? text.match(hashtagRegex) || [] : [];
  };

  const replaceHashtagsWithLinks = (text) => {
    const hashtagRegex = /#(\w+)/g;
    return text.replace(hashtagRegex, (match) => {
      const hashtag = match.slice(1);
      return `<a href="/hashtag/search/${hashtag}" class="text-blue-500 hover:underline">${match}</a>`;
    });
  };

  const url = extractUrls(userBlogDetail && userBlogDetail[0]?.post);
  const urldecode = decodeURIComponent(url);

  useEffect(() => {
    if (url && url.length > 0) {
      const fetchMetaData = async () => {
        try {
          const requestUrl = `${process.env.REACT_APP_API_URL}/slugcity/get/metadata/${encodeURIComponent(url)}`;
          const res = await fetch(requestUrl);
          const data = await res.json();
          setMetaData(data);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        }
      };
      fetchMetaData();
    }
  }, [urldecode]);

  //----------- FETCH COMMENTS ---------------
  useEffect(() => {
    if (blog_id) {
      fetchBlogComments(blog_id);
    }
  }, [blog_id, reload]);


      //like section socket.io
      const handleLikeUpdated = ({ blog_id, like_count }) => {
        console.log('Received likeUpdated event for blog_id:', blog_id, 'with like_count:', like_count);
        setUserBlogDetail((prevBlogs) =>
          prevBlogs.map((blog) =>
            blog.id === blog_id ? { ...blog, like_count: like_count } : blog
          )
        );
      };
    
      useEffect(() => {
        socket.on('likeUpdated', handleLikeUpdated);
    
        return () => {
          socket.off('likeUpdated', handleLikeUpdated);
        };
      }, []);


  const isLiked = likeData && likeData.find((like) => like.blog_id === userBlogDetail[0]?.id);

  return (
    <div className="w-full min-h-screen flex justify-center">
     
      <section className=" w-full  border-l-[1px] border-r-[1px] border-customBorderColor">
        <div className="flex items-center">
          <FaArrowLeft
            className="text-4xl cursor-pointer my-2 mx-2 rounded-full hover:bg-white/10 p-2"
            onClick={handleBackClick}
          />
          blog
        </div>
        {userBlogDetail ?
          userBlogDetail.map((blog) => (
            <div key={blog.id} className="flex gap-4 w-[100%] mt-0 border-b-[1px] border-customBorderColor p-6">
              <section className="w-full">
                
                <div className="flex items-center gap-2 mb-4  w-full h-16">
                <section className=' w-16 h-16 '>
        <img src={`${process.env.REACT_APP_API_URL}/images/${blog && blog.avater}`} className='bg-neutral-950 rounded-full w-12 h-12 object-cover'/>
      </section>
                  <p className="font-semibold flex items-center justify-between">
                    <Link
                      to={`/${blog.username}`}
                      className="hover:text-elementColor "
                    >
                      {blog.username}
                    </Link>
                  </p>
                  <div className="w-full flex justify-end ">
                  {blog.user_id === userId && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                    setOpenDeleteMsg({ status: true, id: blog.id });
                  }}
                  className='text-elementColor text-lg p-1 w-fit border border-customBorderColor flex  hover:bg-black/20'
                >
                  <MdDelete />
                </button>
              )}
</div>
                </div>

                <div className="my-2 text-base text-secTextColor whitespace-pre-wrap hashtag" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(replaceHashtagsWithLinks(blog.post)) }}>
                </div>
                {metaData && (
                  <Link
                    to={metaData.url}
                    target="_blank"
                    rel="noreferrer"
                    className='  '
                    onClick={e => e.stopPropagation()}
                  >
                    <div className='mt-2 border border-customBorderColor p-3 hover:bg-white/10'>
                      <img src={metaData.image} alt={metaData.title} className='w-full h-48 object-cover' />
                      <h4 className='text-base font-semibold my-2'>{metaData.title}</h4>
                      <p className='text-secTextColor text-sm mb-3'>{metaData.description}</p>
                    </div>
                  </Link>
                )}
                <div className="mt-4">
                  {moment(blog.date).format("LLLL")}
                </div>
                {loggedIn ? (
                                <div className="mt-3 text-xl flex gap-2">
                                <span onClick={() => setOpenCommentsPost(prevState => ({ ...prevState, open: !prevState.open, commentType: "blog_id", id: blog.id }))}>
                                  <VscComment />
                                </span>
                                {isLiked ?
                            <span onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                              deleteBlogLike(e, blog.id);
                            }} className='text-elementColor'><IoMdHeart />
                            </span>
                            :
                            <span onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                              addBlogLike(e, blog.id);
                            }}><IoIosHeartEmpty /></span>
                          }
                          <span>{blog.like_count}</span>
                              </div>
                ) : (
                  <div className="mt-3 text-xl flex gap-2">
                  <span >
                    <VscComment />
                  </span>
                  {isLiked ?
              <span onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
               
              }} className='text-elementColor'><IoMdHeart />
              </span>
              :
              <span onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                
              }}><IoIosHeartEmpty /></span>
            }
            <span>{blog.like_count}</span>
                </div>
                )}
              </section>
            </div>
          )): (<div>post not found</div>)}

        {commentData && commentData.map((comment, index) => (
          <Comments
            userId={userId}
            deleteComment={deleteComment}
            postComment={postComment}
            comment={comment}
            setOpenCommentsPost={setOpenCommentsPost}
            index={index}
            key={index}
            openCommentDeleteMsg={openCommentDeleteMsg}
            setOpenCommentDeleteMsg={setOpenCommentDeleteMsg}
            addCommentLike={addCommentLike}
            deleteCommentLike={deleteCommentLike}
            likeData={likeData}
            loggedIn={loggedIn}
          />
        ))}
      </section>
     
    </div>
  );
};

export default UserBlogDetail;