import React from 'react';
import NotificationCard from './NotificationCard';
import { Link } from 'react-router-dom';

const Notification = ({ notifications, readNotification }) => {
    
  return (
    <div className='border-customBorderColor bg-mainColor min-h-screen border-l-[1px] border-r-[1px] '>
      {notifications && notifications.length > 0 ? (
              notifications.map((notification, index) => {
            
          let link;
          if (notification.message === 'comment') {
            link = `/comments/${notification.object_id}`;
          } else if (notification.message === 'follow') {
            link = `/${notification.username}`;
          } else if (notification.message === 'like') {
            link = `/userpostdetail/${notification.object_id}`;
          } else if (notification.message === 'commentLike') {
            link = `/comments/${notification.object_id}`;
          } else {
            link = `/user/${notification.user_id}`;
          }

          return (
            <Link to={link} key={index} onClick={()=> readNotification( notification.id, notification.object_id)}>
              <NotificationCard
                notification={notification}
              />
            </Link>
          );
        })
      ) : (
        <div className='flex justify-center items-center h-full'>
          <p className='text-secTextColor'>No Notifications</p>
        </div>
      )}
    </div>
  );
};

export default Notification;