import React, { useState } from 'react';
import Home from './Home';
import Feeds from './Feeds';

const StartPage = ({
  reload,
  userBlogs,
  userId,
  user,
  deletePost,
  openDeleteMsg,
  setOpenDeleteMsg,
  loggedIn,
  logoutUser,
  likeData,
  addBlogLike,
  deleteBlogLike,
  setOpenCommentsPost,
  bottomRef,
  loading,
  fetchPopularHashtags,
  hashtags,
  followingBlogs,
  repostUserBlog,
  deleteRepost,
  reposts
}) => {
  const [currentComponent, setCurrentComponent] = useState('home');

  const renderComponent = () => {
    if (currentComponent === 'feeds') {
      return (
        <Feeds
          reload={reload}
          userId={userId}
          user={user}
          deletePost={deletePost}
          openDeleteMsg={openDeleteMsg}
          setOpenDeleteMsg={setOpenDeleteMsg}
          loggedIn={loggedIn}
          logoutUser={logoutUser}
          likeData={likeData}
          addBlogLike={addBlogLike}
          deleteBlogLike={deleteBlogLike}
          setOpenCommentsPost={setOpenCommentsPost}
          bottomRef={bottomRef}
          loading={loading}
          fetchPopularHashtags={fetchPopularHashtags}
          hashtags={hashtags}
          followingBlogs={followingBlogs}
          repostUserBlog={repostUserBlog}
          deleteRepost={deleteRepost}
          reposts={reposts}
        />
      );
    } else {
      return (
        <Home
          reload={reload}
          userId={userId}
          user={user}
          deletePost={deletePost}
          openDeleteMsg={openDeleteMsg}
          setOpenDeleteMsg={setOpenDeleteMsg}
          loggedIn={loggedIn}
          logoutUser={logoutUser}
          likeData={likeData}
          addBlogLike={addBlogLike}
          deleteBlogLike={deleteBlogLike}
          userBlogs={userBlogs}
          setOpenCommentsPost={setOpenCommentsPost}
          bottomRef={bottomRef}
          loading={loading}
          hashtags={hashtags}
          followingBlogs={followingBlogs}
          repostUserBlog={repostUserBlog}
          deleteRepost={deleteRepost}
          reposts={reposts}
        />
      );
    }
  };

  return (
    <div className='w-full h-full'>
      {loggedIn && 
          <nav className='sticky top-[51px] bg-mainColor w-full flex justify-center h-[55px]  border border-customBorderColor '>
          <button className='w-1/2 hover:bg-menuHover' onClick={() => setCurrentComponent('home')}>
            Home
          </button>
          <button className='w-1/2 hover:bg-menuHover' onClick={() => setCurrentComponent('feeds')}>
            Feeds
          </button>
        </nav>
}
      <section>{renderComponent()}</section>
    </div>
  );
};

export default StartPage;