import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";

const Navigation = ({ loggedIn, logoutUser, user }) => {
  const [isOpen, setIsOpen] = useState(false);

  const menu = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about" },
    { name: "Services", link: "/services" },
    { name: "Contact", link: "/contact" },
  ];

  const userMenu = [
    { name: "Profile", link: "/profile" },
    { name: "Settings", link: "/settings" },
    { name: "Logout", link: "/", fnc: logoutUser },
  ];

  return (
    <>
      <nav className="z-50 xl:w-[100%] shadow-md shadow-black/30 w-full h-full mx-auto bg-headerColor justify-between border-b-[1px] border-customBorderColor items-center sticky top-0 ">
        <section className="flex justify-between h-[50px] items-center py-4 px-5">
          
          <Link to="/" className="xl:text-2xl text-headerTextColor text-lg font-bold">
            SLUGCITY<span className="text-linkText  font-bold italic">TV</span>.com
          </Link>
          <section className="xl:text-2xl text-white text-lg font-bold"></section>
          <section className="xl:text-5xl text-white text-lg font-extrabold">
            
          </section>
          <section className="text-2xl text-white flex items-center gap-4 ">
            {!loggedIn ? (
              <Link to="/login" className="text-headerTextColor text-xl cursor-pointer">
                <FaUser />
              </Link>
            ) : (
              <p className="font-medium text-sm text-red-500 ">
                <span className="text-white">{user && user.username}</span>
              </p>
            )}
            <div
              className="text-white xl:hidden cursor-pointer ml-2 text-3xl"
              onClick={() => setIsOpen(!isOpen)}
            >
              <RxHamburgerMenu />
            </div>
          </section>
        </section>
        {isOpen && (
          <section
            id="hamburger-menu"
            className="flex flex-col gap-1 h-[calc(100vh -60px)] xl:w-[280px] w-full right-0 items-center bg-[#ffffff]  absolute "
          >
            <p className="w-full flex items-center px-2 h-8 bg-neutral-900 text-white">
              NAVIGATION
            </p>
            {menu &&
              menu.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className="text-base pl-4 py-2 w-full hover:text-sky-400 duration-100 border-dotted border-b-[1px] border-b-gray-600"
                >
                  {item.name}
                </Link>
              ))}
  
            {loggedIn &&
              <>
                         <p className="w-full flex items-center px-2 h-8 bg-[#1d1f22] text-white">
                        USER MENU
                      </p>
              {userMenu.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  onClick={
                    item.fnc
                      ? (e) => {
                        e.preventDefault();
                        item.fnc();
                      }
                      : null
                  }
                  className="text-base pl-4 py-2 w-full hover:text-sky-400 duration-100 border-dotted border-b-[1px] border-b-gray-600"
                >
                  {item.name}
                </Link>
              ))}
            </>
             }
          </section>
        )}
      </nav>
    </>
  );
};

export default Navigation;
