import React, { useEffect, useRef } from "react";
import moment from "moment";
const Chat = ({
  conversations,
  setConversationId,
  chatMessages,
  userId,
  setMessageData,
  messageData,
  postChatMessage,
  openChatWindow
}) => {
  const chatWindowRef = useRef(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chatMessages]);

  return (
    <div className=" bg-bgColor flex items-center justify-center fixed top-0 z-50 w-full h-screen">
      <button onClick={openChatWindow} className="absolute flex items-center justify-center top-5 right-5 bg-menuHover w-[50px] h-[50px] rounded-full ">X</button>
      <div className="bg-headerColor border-customBorderColor border p-4 rounded-md xl:w-[80%] w-full h-[96%] flex ">
        <section className="w-[20%] h-full bg-headerColor border-customBorderColor border">
          {conversations &&
            conversations.map((conversation) => {
              const isUser1 = conversation.user1_id === userId;
              const otherUser = isUser1
                ? conversation.user2
                : conversation.user1;
              const otherUserAvatar = isUser1
                ? conversation.user2_avatar
                : conversation.user1_avatar;

              return (
                <div
                  onClick={() => setConversationId(conversation.id)}
                  key={conversation.id}
                  className="p-2 border-b border-customBorderColor hover:bg-menuHover"
                >
                  <div className="flex items-center">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/images/${otherUserAvatar}`}
                      alt="profile"
                      className="w-[50px] h-[50px] rounded-full"
                    />
                    <div className="ml-2">
                      <p>{otherUser}</p>
                      <p>{conversation.lastmessage}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </section>
        <section id="messages" className="w-[70%] relative mx-2">
          <div
            id="chatwindow"
            ref={chatWindowRef}
            className="w-full overflow-scroll pb-[50px] bg-bgColor h-full border-customBorderColor border"
          >
            {chatMessages &&
              chatMessages.map((message) => (
                <div
                  key={message.id}
                  className={`flex items-center w-full mb-4  rounded-lg`}
                >
                  <div className="flex w-full">
                    <div className="mr-2 flex gap-2 w-full">
                    <img src={`${process.env.REACT_APP_API_URL}/images/${message.avater}`} className="w-14 h-14 rounded-full" alt="" />
                      <div
                        className={`p-2 w-full rounded-sm border border-customBorderColor ${
                          userId === message.sender_id
                            ? "bg-chatColor1"
                            : "bg-chatColor2"
                        }`}
                      >
                        
                        <p>{message.content}</p>
                        <p className="text-sm text-secTextColor">
                          {moment(message.created_at).fromNow()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <form className="absolute bottom-0 p-2 bg-menuHover w-full flex items-center">
            <input
              onChange={(e) => setMessageData(e.target.value)}
              value={messageData}
              className="w-[90%] m-0"
              type="text"
            />
            <button
              onClick={postChatMessage}
              className="w-[10%] bg-headerColor hover:bg-elementColor h-[45px] border-customBorderColor border"
            >
              send
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Chat;
