import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
const NotificationCard = ({ notification  }) => {
    
  const handleNotification = (message) => {
    if (message.includes('commentLike')) {
        return 'liked your comment';
    }
    if (message.includes('comment')) {
        return 'commented your post';
    }
    if (message.includes('like')) {
        return 'liked your post';
    }
    if (message.includes('follow')) {
        return 'followed you';
    }
};
    
  return (
    <div className={`flex w-full p-4 border-customBorderColor border-b-[1px] hover:bg-menuHover ${notification.seen === true? "bg-bgColor": "bg-white/10"}`}>
      <section className='w-24'>
        <Link to={`/${notification.username}`} className='font-medium text-lg hover:text-linkText'>
        <img className='w-16 h-16 rounded-full' src={`${process.env.REACT_APP_API_URL}/images/${notification.avater}`} alt="" />
        </Link>
           
          </section>
          <section className='pl- w-full'>
        <div className='w-full flex justify-between '>
          <Link to={`/${notification.username}`} className='font-medium text-lg hover:text-linkText'>{notification.username}</Link>
               
               <span className='text-secTextColor'>{moment(notification.date).fromNow()}</span>
            </div>
              <p className='text-secTextColor'>{ handleNotification(notification.message) }</p>
          </section>    
    </div>
  )
}

export default NotificationCard