import React from 'react'

const DeleteUserPost = ({ openDeleteMsg, setOpenDeleteMsg,deletePost}) => {
  return (
    <div  className='flex items-center justify-center w-full z-40 h-screen modal-background backdrop-blur-sm fixed'>
    <form onClick={(e) => e.stopPropagation()} className='flex flex-col gap-4 items-center justify-center xl:w-[650px] w-full xl:h-[400px] h-full xl:p-10 px-3 bg-headerColor rounded-md' action="">
              <p className='font-semibold text-lg'>delete your post?</p>
              <button onClick={(e)=> deletePost(e,openDeleteMsg.id)} className='text-elementColor font-semibold border border-neutral-500 rounded-full px-8 py-2 hover:bg-elementColor hover:text-white'>Delete</button>
              <button onClick={() => setOpenDeleteMsg(false) } className='font-semibold border border-neutral-500 rounded-full px-8 py-2 hover:bg-black/30'>Cancle</button>
    </form>
  </div>
  )
}

export default DeleteUserPost