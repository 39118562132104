import React, { useState, useEffect } from "react";
import { TiMessages } from "react-icons/ti";

const UserProfileCard = ({
  setOpenProfileEdit,
  followerData,
  user,
  userId,
  userProfileData,
  followUser,
  unfollowUser,
  isFollowing,
  setIsFollowing,
  followerCount,
  followingCount
}) => {
  

  useEffect(() => {
    if (userProfileData && followerData.length > 0 && user) {
      const isFollowing = followerData.some(
        (follower) =>
          follower.user_id === user.id &&
          follower.follower_id === userProfileData.id
      );
      setIsFollowing(isFollowing);
    }
  }, [userProfileData, followerData, user]);

  if (!userProfileData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full flex flex-col border-b-[1px] pb-5 border-customBorderColor">
      <section id="banner" className="h-[220px] xl:h-[250px] bg-neutral-950 object-fill" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}/images/${userProfileData ? userProfileData.banner : null})`, backgroundSize: 'cover', backgroundPosition: 'top' }}></section>
      <section className="flex justify-between w-full">
        <img src={`${process.env.REACT_APP_API_URL}/images/${userProfileData && userProfileData.avater}` } className=" w-36 h-36 xl:w-36 xl:h-36 rounded-full border-white ml-[20px] mt-[-40px] border-[2px] object-cover"/>
          
        
        <div className="p-4 flex items-center gap-3">
       
          {user ? (
            user.id === userProfileData.id ? (
              <div onClick={()=> setOpenProfileEdit(true)} className="flex items-center  justify-center bg-mainColor text-sm border border-customBorderColor text-secTextColor h-[35px] w-[130px] px-3 py-2 rounded-sm">
                Edit profile
              </div>
            ) : isFollowing ? (
              <button onClick={e => unfollowUser(e, userProfileData.id)} className="flex items-center font-medium justify-center  bg-buttonSecColor text-black h-[35px] w-[130px] px-3 py-2 rounded-sm">
                unfollow
              </button>
            ) : (
              <button onClick={e => followUser(e, userProfileData.id)} className="flex items-center font-medium justify-center bg-elementColor text-buttonTextColor h-[35px] w-[130px] px-3 py-2 rounded-sm">
                follow
              </button>
            )
          ) : (
            <div className="flex items-center bg-mainColor text-sm justify-center border border-customBorderColor text-secTextColor h-[35px] px-9 py-2 w-full rounded-sm">
              Login to follow
            </div>
          )}
        </div>
      </section>
      <section className="flex flex-col ml-[20px] mt-4 ">
      <span className="flex text-2xl items-center font-semibold">
          {userProfileData && userProfileData.name? userProfileData.name : userProfileData.username}
        </span>
        <span className="text-secTextColor">@{userProfileData && userProfileData.username}</span>
        <span className="font-semibold mt-3">followers: {followingCount } following: {followerCount} </span>
        <span className="mt-4 text-secTextColor">{userProfileData && userProfileData.description}</span>
        
      </section>
    </div>
  );
};

export default UserProfileCard;
