import React, { useEffect, useState } from 'react';
import { IoIosHeartEmpty, IoMdHeart } from "react-icons/io";
import DOMPurify from 'dompurify';
import { VscComment } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";
import { BiRepost } from "react-icons/bi";
import { VscGraph } from "react-icons/vsc";

import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

const PostCard = ({
  blog,
  userId,
  deletePost,
  openDeleteMsg,
  setOpenCommentsPost,
  setOpenDeleteMsg,
  likeData,
  addBlogLike,
  deleteBlogLike,
  loggedIn,
  repostUserBlog,
  deleteRepost,
  reposts
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [commentData, setCommentData] = useState([]);

  const navigate = useNavigate();

  // ------------------------- EXTRACT URLS FROM TEXT  ---------------------------
  const extractUrls = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text ? text.match(urlRegex) : null;
  };

  const url = extractUrls(blog.post);
  const urldecode = decodeURIComponent(url);

  // ------------------------- EXTRACT HASHTAGS FROM TEXT  ---------------------------
  const extractHashtags = (text) => {
    const hashtagRegex = /#(\w+)/g;
    return text ? text.match(hashtagRegex) || [] : [];
  };

  // ------------------------- REPLACE HASHTAGS WITH LINKS  ---------------------------
  const replaceHashtagsWithLinks = (text) => {
    const hashtags = extractHashtags(text);
    let newText = text;
    hashtags.forEach((hashtag) => {
      const link = `<a href="/hashtag/search/${hashtag.substring(1)}" class="hashtag-link ">${hashtag}</a>`;
      newText = newText.replace(hashtag, link);
    });
    return newText;
  };

  const updatedPost = replaceHashtagsWithLinks(blog.post);

    // Sanitize the updated post content
    const sanitizedPost = DOMPurify.sanitize(updatedPost);

  useEffect(() => {
    if (url && url.length > 0) {
      const fetchMetaData = async () => {
        try {
          const requestUrl = `${process.env.REACT_APP_API_URL}/slugcity/get/metadata/${encodeURIComponent(url)}`;
          const res = await fetch(requestUrl);
          const data = await res.json();
          setMetaData(data);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        }
      };
      fetchMetaData();
    }
  }, [urldecode]);

  // ----------------- FETCH COMMENTS to show LENGTH ---------------------------------------------
  useEffect(() => {
    if (blog) {
      const fetchComments = async () => {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/slugcity/get/commentslength?blog_id=${blog.id}`);
          const data = await res.json();
          setCommentData(data);
        } catch (error) {
          console.error('Error fetching comments:', error);
        }
      };
      fetchComments();
    }
  }, [blog]);

  // ---------- likes ------------
  const isLiked = likeData && likeData.find((like) => like.blog_id === blog.original_post_id);
  const [repostSwitch, setRepostSwitch] = useState(false);
  const repostExist = reposts && reposts.find((repost) => repost.original_post_id === blog.id);
  const idOfExistingRepost = repostExist ? repostExist.id : null;

  const isReposted = blog && blog.repost_user_id === userId;
  console.log({"repostFound":reposts })
  return (
    <div onClick={() => navigate(`/userpostdetail/${blog && blog.original_post_id ? blog.original_post_id : blog.id}`)} className='cursor-pointer flex gap-3 w-[100%] my-0 border-b-[1px] border-customBorderColor p-4 hover:bg-gray-500/5'>
      
      <section className=' w-14 h-14 '>
        <Link   onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
              }} to={`/${blog && blog.username}`}>
        <img src={`${process.env.REACT_APP_API_URL}/images/${blog && blog.repost_avater ?blog.repost_avater : blog.avater}`} className='bg-neutral-950 rounded-full w-12 h-12 object-cover'/>
        </Link>
      </section>
      <section className='w-full'>
      {blog.repost_name ?<p className='flex items-center gap-1 text-sm font-medium text-elementColor'><BiRepost/>{blog.repost_name && blog.repost_name } reposts</p> : null}
        <section id="content" className={`${blog && blog.repost_user_id ? "border border-customBorderColor p-2 rounded-lg bg-white/5" : "p-0"}`}>
        <div className='w-full font-semibold flex justify-between '>
          <span className='flex items-center '>
            <Link
              to={`/${blog && blog.username}`}
              className='hover:text-elementColor w-full'
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
              }}
            >
              {blog && blog.original_name? blog.original_name : blog.name}
            </Link>
            <span className='ml-1 font-normal text-sm text-secTextColor'>@{ blog && blog.original_username? blog.original_username : blog.username}</span>
          </span>
          <div className='flex items-center gap-4 '>
            <span className='text-sm text-secTextColor font-medium'>
              {moment(blog && blog.date).fromNow()}
            </span>
            <span>
              {blog.user_id === userId && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                    setOpenDeleteMsg({ status: true, id: blog.id });
                  }}
                  className='text-elementColor text-lg p-1 w-fit border border-customBorderColor flex  hover:bg-black/20'
                >
                  <MdDelete />
                </button>
              )}
            </span>
          </div>
        </div>
        <div className='mt-1 text-base whitespace-pre-wrap break-words flex flex-wrap text-wrap'>
          <span className='text-secTextColor hashtag' dangerouslySetInnerHTML={{ __html: isExpanded ? sanitizedPost : `${sanitizedPost.substring(0, 250)}` }}></span>
          {blog && blog.post.length > 250 && (
            <span 
              className='font-semibold cursor-pointer' 
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? ' ...show less' : ' ...read more'}
            </span>
          )}
          {metaData && (
            <>
              <Link
                to={metaData.url}
                target="_blank"
                rel="noreferrer"
                className=' w-full'
                onClick={e => e.stopPropagation()}
              >
                <div className='mt-3 p-3  border bg-black-50 rounded-md border-customBorderColor'>
                  <img src={metaData.image} alt={metaData.title} className='w-full h-48 object-cover rounded-md' />
                  <h4 className='text-base font-semibold my-2'>{metaData.title}</h4>
                  <p className='text-secTextColor mb-2 break-words'>{metaData.description}</p>
                </div>
              </Link>
            </>
          )}
        </div>
       </section>
        {loggedIn ?
          (
            <div className='mt-4 text-xl text-secTextColor flex justify-between items-center '>
            <span
              className='flex items-center gap-1'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                setOpenCommentsPost(prevState => ({ ...prevState, open: !prevState.open, commentType: "blog_id", id: blog.id }))
              }}
            >
              <VscComment />{commentData && commentData.length}
            </span>
            <div className='flex gap-1 items-center'>
              {isLiked ?
                <span onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                  deleteBlogLike(e,blog && blog.original_post_id? blog.original_post_id : blog.id);
                }} className='text-elementColor'><IoMdHeart />
                </span>
                :
                <span onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                  addBlogLike(e,blog && blog.original_post_id? blog.original_post_id : blog.id);
                }}><IoIosHeartEmpty /></span>
              }
                <span>{blog.like_count}</span>
                
              </div>
              {isReposted || repostExist || repostSwitch?
                (<div
                   className='text-sky-500'
                   onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                     deleteRepost(e, repostExist ? idOfExistingRepost : blog.id);
                      setRepostSwitch(false)
                  }}
              >
                < BiRepost size={28} />
                </div>
                )
                :
                (
                  <div
                   
                  onClick={(e) => {
                   e.preventDefault();
                   e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                      repostUserBlog(e, blog && blog.original_post_id? blog.original_post_id : blog.id);
                      setRepostSwitch(true)
                 }}
             >
               < BiRepost size={28} />
               </div>
                )
              }
              <div><VscGraph/></div>
          </div>
          ) : (
            <div className='mt-4 text-xl text-secTextColor flex justify-between items-center gap-2'>
            <span
              className='flex items-center gap-1'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                
              }}
            >
              <VscComment />{commentData && commentData.length}
            </span>
            <div className='flex gap-1  items-center'>
              {isLiked ?
                <span onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                
                }} className='text-elementColor'><IoMdHeart />
                </span>
                :
                <span onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                 
                }}><IoIosHeartEmpty /></span>
              }
              <span>{blog.like_count}</span>
              </div>
              <div>< BiRepost size={28} /></div>
              <div><VscGraph/></div>
          </div>
          )
}
      </section>
    </div>
  );
};

export default PostCard;