import React,{useEffect,useState} from 'react'

const CommentsPost = ({ setOpenCommentsPost, openCommentsPost, postComment }) => {

  const commentType = openCommentsPost && openCommentsPost.commentType;
  const commentId = openCommentsPost && openCommentsPost.id;

  const [commentContent, setCommentContent] = useState("");
  console.log({"commentType": commentType, "commentId": commentId, "commentContent": commentContent});

    const [charCount, setCharCount] = useState(0);
    const CHARACTER_LIMIT = 300;
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      if (value.length <= CHARACTER_LIMIT) {
        setCommentContent(value);
      }
    };
  return (
    <div  className='flex modal-background items-center justify-center w-full z-40 h-screen bg-white/20 backdrop-blur-sm fixed'>
      <form onClick={(e) => e.stopPropagation()} className='flex relative flex-col items-center justify-center xl:w-[650px] w-full xl:h-[460px] h-[460px] xl:p-10 px-3 bg-headerColor rounded-xl' action="">
      <button onClick={() => setOpenCommentsPost(prevState => ({ ...prevState, open: !prevState.open }))} className='rounded-full bg-gray-600 absolute top-2 w-8 h-8 right-2'>X</button>
      <textarea
        onChange={handleInputChange}
        
        className='xl:w-[550px] w-[100%]   p-2'
        name=""
        id=""
        cols="30"
        rows="6"
      ></textarea>
      <div className=' xl:w-[550px] w-[100%] flex justify-end'>{charCount}/{CHARACTER_LIMIT}</div>
      <button onClick={ e => postComment(e, commentId, commentContent, commentType)} className='w-[200px] rounded-sm bg-elementColor font-semibold text-lg text-white px-4 py-2 mt-3'>Post Comment</button>
    </form>
  </div>
  )
}

export default CommentsPost