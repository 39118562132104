import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { IoHome } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { IoSettingsSharp } from "react-icons/io5";
import { IoLogOut } from "react-icons/io5";
import { LuMessagesSquare } from "react-icons/lu";
import { IoNotificationsCircleOutline } from "react-icons/io5";


const Navigation = ({ loggedIn, logoutUser, user, openChatWindow, notifications }) => {
  
  const [unseenNotification, setUnseenNotification] = useState(0);

  useEffect(() => {
    if (notifications) {
      const unseenCount = notifications.filter(notification => !notification.seen).length;
      setUnseenNotification(unseenCount);
    }
  }, [notifications]);
  
  const menu = [
        {icon: <IoHome/>  , name: "Home", link: "/" },
  ];
  
  const userMenu = [
    { icon: <CgProfile />, name: "Profile", link: `/${user && user.username}` },
    { icon: <IoNotificationsCircleOutline />, name: `Notification`, notification:`${unseenNotification? unseenNotification : ""}`, link: `/user/notification` },
   // { icon: <LuMessagesSquare />, name: "Messages", link: "/", fnc: openChatWindow },
    { icon: <IoSettingsSharp />, name: "Settings", link: "/settings" },
    { icon: <IoLogOut />, name: "Logout", link: "/", fnc: logoutUser },
  ];
    
  return (
    <section className=' xl:flex  flex-col bg-mainColor z-20 hidden w-[300px] min-h-screen border-l-[1px] border-customBorderColor '>
        <div className='flex flex-col sticky mx-6 top-28'>
        
          {menu &&
              menu.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className=" flex items-center gap-4 text-xl pl-6 hover:bg-menuHover rounded-md  py-2 w-full hover:text-white duration-100 border-b-[0px]  border-b-customBorderColor"
                >
                  { item.icon }{item.name}
                </Link>
              ))}
  
            {loggedIn &&
              <>
                       
              {userMenu && userMenu.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  onClick={
                    item.fnc
                      ? (e) => {
                        e.preventDefault();
                        item.fnc();
                      }
                      : null
                  }
                  className="flex items-center gap-4 text-xl pl-6 py-2 w-full hover:hover:bg-menuHover rounded-md  hover:text-white duration-100  border-b-[0px] border-b-customBorderColor"
                >
                  { item.icon  }{item.name} {item.notification && <span className='bg-elementColor w-6 h-6 flex justify-center items-center text-sm rounded-lg'>{item.notification}</span>}
                </Link>
              ))}
            </>
             }
      </div>
      </section>
  )
}

export default Navigation