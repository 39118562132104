import { useEffect, useState, useRef, useCallback } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import io from "socket.io-client";
import Navigation from "./Components/Navigation";
import Home from "./Components/Home/Home";
import UserProfile from "./Components/UserProfile/UserProfile";
import Login from "./Components/Login/Login";
import AddUserPostButton from "./Components/Modals/AddUserPostButton";
import AddUserPostForm from "./Components/Modals/AddUserPostForm";
import UserBlogDetail from "./Components/UserBlogDetail/UserBlogDetail";
import HashtagInput from "./Components/Hashtag/HashtagInput";
import AskForDelete from "./Components/Modals/DeleteUserPost";
import CommentsDetail from "./Components/Comments/CommentsDetail";
import CommentsPost from "./Components/Comments/CommentsPost";
import DeleteComment from "./Components/Modals/DeleteComment";
import MobileNavigation from "./Components/Navigation/MobileNavigation";
import Search from "./Components/Search/Search";
import Menu from "./Components/Navigation/Navigation";
import Trending from "./Components/Navigation/Trending";
import Chat from "./Components/Chat/Chat";
import Notification from "./Components/Notification/Notification";
import StartPage from "./Components/Home/StartPage";

const socket = io(process.env.REACT_APP_API_URL);

function App() {
  const [openUserPostForm, setOpenUserPostForm] = useState(false);
  const [openDeleteMsg, setOpenDeleteMsg] = useState(false);
  const [openCommentDeleteMsg, setOpenCommentDeleteMsg] = useState(false);
  const [openCommentsPost, setOpenCommentsPost] = useState({
    commentType: "blog",
    open: false,
    id: 0,
  });
  const [message, setMessage] = useState("");
  const [reload, setReload] = useState(false);
  const [reloadBlogLikes, setReloadBlogLikes] = useState(false);
  const [reloadCommentLikes, setReloadCommentLikes] = useState(false);
  const [reloadFollowers, setReloadFollowers] = useState(false);
  const [inputFields, setInputFields] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loginEmail, setLoginEmail] = useState("");
  const [username, setUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [userPost, setUserPost] = useState("");
  const [likeData, setLikeData] = useState([]);
  const [followerData, setFollowerData] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [comment, setComment] = useState({});
  const [childComment, setChildComment] = useState([]);
  const [userBlogs, setUserBlogs] = useState([]);
  const [userBlogDetail, setUserBlogDetail] = useState("");
  const [blogs, setBlogs] = useState([]);
  const userId = user && user.id;

  const handleChange = (e) => {
    e.preventDefault();
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  // useNavigate for redirecting after login
  const navigate = useNavigate();

  //get token and fetch userdata
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("slugcity_token");
      setToken(token);

      if (!token) {
        return;
      }

      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/slugpage/get/userdata`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();

        if (res.status === 200) {
          setUser(data.user);
          setLoggedIn(true);
          //navigate('/dashboard?view=home');
        } else if (res.status === 500) {
          // Token is expired or invalid
          localStorage.removeItem("slugcity_token");
          setUser(null);
          setLoggedIn(false);
        }
      } catch (error) {
        console.log({ "fetch user data error": error });
      }
    };

    fetchUserData();
  }, [token]);

  //register user
  const registerUser = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugpage/post/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            email: loginEmail,
            password: loginPassword,
          }),
        }
      );
      const data = await res.json();

      if (res.status === 201) {
        localStorage.setItem("slugcity_token", data.token);
        setToken(data.token);
        setUser(data.user);
        setLoggedIn(true);
        navigate("/");
        setUsername("");
        setLoginEmail("");
        setLoginPassword("");
      }
    } catch (error) {
      console.log({ "register user error": error });
    }
  };

  // --------------- login user -----------------------------------------------
  const loginUser = async (e) => {
    e.preventDefault();
    console.log("logging in user");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugpage/post/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: loginEmail, password: loginPassword }),
        }
      );
      const data = await res.json();

      if (res.status === 200) {
        localStorage.setItem("slugcity_token", data.token);
        setToken(data.token);
        setUser(data.user);
        setLoggedIn(true);
        navigate("/");
      }
    } catch (error) {
      console.log({ "login user error": error });
    }
  };

  // ----------------------- LOGOUT USER ----------------------------------------------

  const logoutUser = async () => {
    localStorage.removeItem("slugcity_token");
    setToken(null);
    setUser(null);
    setLoggedIn(false);
    setLoginEmail(null);
    setLoginPassword(null);
    setFollowerData([])
    navigate("/");
    //window.location.reload();
  };

  //----------------------- FORGOT PASSWORD ---------------------------------------

  const forgotPassword = async (e) => {
    e.preventDefault();
    console.log("forgot password");
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugpage/post/forgotpwd`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: loginEmail }),
        }
      );
      const data = await res.json();
      console.log(data);
      setLoginEmail(""); //reset email
      if (res.status === 200) {
        setMessage(data.message);
      }
    } catch (error) {
      console.log({ "forgot password error": error });
    }
  };

  // ----------- FETCH USER BLOGS   --------------------------------------

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const bottomRef = useRef();
  const location = useLocation();

  const fetchBlogs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/get/alluserblogposts?page=${page}`
      );
      const data = await response.json();
      setUserBlogs((prevBlogs) => {
        const newBlogs = data.filter(
          (blog) => !prevBlogs.some((prevBlog) => prevBlog.id === blog.id)
        );
        return [...prevBlogs, ...newBlogs];
      });
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    if (location.pathname === "/") {
      fetchBlogs();
    }
  }, [page, location.pathname, fetchBlogs]);

  const observerCallback = useCallback((entries) => {
    if (entries[0].isIntersecting) {
      setPage((prevPage) => prevPage + 1);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      const observer = new IntersectionObserver(observerCallback, {
        rootMargin: "60px",
        threshold: 1.0,
      });

      if (bottomRef.current) {
        observer.observe(bottomRef.current);
      }

      return () => {
        if (bottomRef.current) {
          observer.unobserve(bottomRef.current);
        }
      };
    }
  }, [observerCallback, location.pathname]);

  //like section socket.io
  const handleLikeUpdated = ({ blog_id, like_count }) => {
    setUserBlogs((prevBlogs) =>
      prevBlogs.map((blog) =>
        blog.id === blog_id ? { ...blog, like_count: like_count } : blog
      )
    );
  };

  useEffect(() => {
    socket.on("likeUpdated", handleLikeUpdated);

    return () => {
      socket.off("likeUpdated", handleLikeUpdated);
    };
  }, []);

  //------------------------ POST user blog ----------------------------------------

  // Sanitize the input content
  const sanitizedContent = DOMPurify.sanitize(userPost);

  // Function to extract hashtags from content
  const extractHashtags = (content) => {
    const hashtagPattern = /#(\w+)/g;
    const hashtags = [];
    let match;
    while ((match = hashtagPattern.exec(content)) !== null) {
      hashtags.push(match[1]);
    }
    return hashtags;
  };

  const postUserBlog = async (e) => {
    e.preventDefault();
  
    // Extract hashtags from sanitized content
    const hashtags = extractHashtags(sanitizedContent);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/post/userblogpost`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            post: sanitizedContent,
            user_id: userId,
            hashtags,
          }),
        }
      );
      const data = await res.json();
      console.log(data);
      if (res.status === 201) {
        setUserBlogs((prevPosts) => [data.post, ...prevPosts]);
        setBlogs((prevBlogs) => [data.post, ...prevBlogs]);
      } else {
        console.error(data.message);
      }
      setUserPost("");
      setOpenUserPostForm(false);
      setReload(!reload);
    } catch (error) {
      console.log({
        "post user blog error": error,
      });
    }
  };

  // ---------------------- DELETE USER BLOG POST ---------------------------------------------

  const deletePost = async (e, id) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/delete/userblogpost/${id}`,
        {
          method: "DELETE",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!res.ok) {
        const errorData = await res.json();
        console.log({ "delete post error": errorData });
        throw new Error(`Error: ${res.status} ${res.statusText}`);
      }

      const data = await res.json();

      // Update userBlogs state by filtering out the deleted post
      setUserBlogs((prevUserBlogs) =>
        prevUserBlogs.filter((post) => post.id !== id)
      );
      setBlogs((prevBlogs) => prevBlogs.filter((post) => post.id !== id));

      setReload(!reload);
      setOpenDeleteMsg(false);
    } catch (error) {
      console.log({ "delete post error": error.message });
    }
  };

  // ------------------------------- GET LIKES BY USER_ID --------------------------------------------

  useEffect(() => {
    if (userId) {
      const fetchLikes = async () => {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/slugcity/get/user/likes`,
            {
              method: "POST",
              body: JSON.stringify({ user_id: userId }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await res.json();
          setLikeData(data);
        } catch (error) {
          console.log({ "fetch likes error": error });
        }
      };
      fetchLikes();
    } else {
      setLikeData([]);
    }
  }, [reloadBlogLikes, userId, token]);

  // ---------------------------- GET FOLLOWERS BY USER_ID -------------------------------------------

  useEffect(() => {
    if (userId) {
      const fetchFollowers = async () => {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/slugcity/get/users/followers`,
            {
              method: "POST",
              body: JSON.stringify({ user_id: userId }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await res.json();
          setFollowerData(data);
        } catch (error) {
          console.log({ "fetch followers error": error });
        }
      };
      fetchFollowers();
    } else {
      setFollowerData([]);
    }

   return () => {
      setFollowerData([]);
    }
  }, [userId, token, reloadFollowers]);

  // --------------------------- ADD DELETE BLOG LIKE -------------------------------------------
  const addBlogLike = async (e, blog_id) => {
    e.preventDefault();
    console.log("adding blog like");
    console.log(blog_id);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/add/blog/like`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ blog_id: blog_id, user_id: userId }),
        }
      );
      const data = await res.json();
      console.log(data);
      setReloadBlogLikes(!reloadBlogLikes);
    } catch (error) {
      console.log({ "add blog like error": error });
    }
  };

  const deleteBlogLike = async (e, blog_id) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/delete/blog/like`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ blog_id: blog_id, user_id: userId }),
        }
      );
      const data = await res.json();
      console.log(data);
      setReloadBlogLikes(!reloadBlogLikes);
    } catch (error) {
      console.log({ "delete blog like error": error });
    }
  };

  // --------------------------------- FOLLOW USER ----------------------------------------------
  const followUser = async (e, user_id) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/post/follow`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ user_id: userId, follower_id: user_id }),
        }
      );
      const data = await res.json();
      console.log(data);
      setReloadFollowers(!reloadFollowers);
      setIsFollowing(true);
    } catch (error) {
      console.log({ "follow user error": error });
    }
  };

  // --------------------------------- UNFOLLOW USER ------------------------------------------------
  const unfollowUser = async (e, user_id) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/post/unfollow`,
        {
          method: "POST",
          body: JSON.stringify({ follower_id: user_id, user_id: user.id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      console.log(data);
      setIsFollowing(false);
      setReloadFollowers(!reloadFollowers);
    } catch (error) {
      console.log({ "unfollow user error": error });
    }
  };

  // ---------------------- FETCH / POST / DELETE COMMENT -------------------------------------------

  const postComment = async (e, id, comment, commentType) => {
    e.preventDefault();

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/post/comment?${commentType}=${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ content: comment, user_id: userId }),
        }
      );
      const data = await res.json();
      console.log(data);
      setOpenCommentsPost({ commentType: "blog", open: false, id: 0 });
      setReload(!reload);
    } catch (error) {
      console.log({
        "post comment error": error,
      });
    }
  };

  const fetchBlogComments = async (blog_id) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/get/comments?blog_id=${blog_id}`
      );
      const data = await res.json();
      setCommentData(data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  const fetchComment = async (comment_id) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/slugcity/get/single-comments?comment_id=${comment_id}`
    );
    const data = await res.json();
    setComment(data);
  };

  const fetchChildComment = async (comment_id) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/slugcity/get/child-comments?comment_id=${comment_id}`
    );
    const data = await res.json();
    setChildComment(data);
  };

  const deleteComment = async (e, comment_id) => {
    e.preventDefault();
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/delete/comment/${comment_id}`,
        {
          method: "DELETE",
          body: JSON.stringify({ user_id: userId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      setReload(!reload);
      setOpenCommentDeleteMsg(false);
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  // LIKE DISLIKE COMMENTS
  const addCommentLike = async (e, comment_id) => {
    e.preventDefault();
   
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/add/comment/like`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({comment_id, user_id: userId }),
        }
      );
      const data = await res.json();
      console.log(data);
      setReloadCommentLikes(!reloadCommentLikes);
      setLikeData(prev => [...prev, data.like]);
    } catch (error) {
      console.log({ "add blog like error": error });
    }
  };

  const deleteCommentLike = async (e, comment_id) => {
    e.preventDefault();
    console.log({"del comment like": comment_id});
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/delete/comments/like`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ comment_id, user_id: userId }),
        }
      );
      const data = await res.json();
      console.log(data);
      setReloadCommentLikes(!reloadCommentLikes);
      setLikeData(prev => prev.filter(like => like.comment_id !== comment_id));
    } catch (error) {
      console.log({ "delete blog like error": error });
    }
  };

  // ------------------- GET  HASHTAGS POPULAR ---------------------------------------------
  const [hashtags, setHashtags] = useState([]);

  useEffect(() => {
    const fetchPopularHashtags = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/slugcity/get/popularhashtags`
        );
        const data = await response.json();
        setHashtags(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPopularHashtags();
  }, [location.pathname === "/"]);

  // ------------------- SEARCH FOR HASHTAG ---------------------------------------------
  const [searchHashtagPost, setSearchHashtagPost] = useState("");

  const handleSearch = async (hashtag_name) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/get/blog/by/hashtagid/${hashtag_name}`
      );
      const data = await response.json();
      setSearchHashtagPost(data);
    } catch (err) {
      console.log(err);
    }
  };

  // ------------------- FOLLOWER & FOLLOWS ---------------------------------------------

  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [profileId, setProfileId] = useState(0);

  useEffect(() => {
    if (profileId) {
      const fetchFollowers = async (profileId) => {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/slugcity/get/followers/${
              profileId && profileId
            }`
          );
          const data = await res.json();
          setFollowerCount(data.length);
        } catch (error) {
          console.log({ "fetch followers error": error });
        }
      };
      fetchFollowers(profileId);

      const fetchFollowing = async (profileId) => {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/slugcity/get/following/${profileId}`
          );
          const data = await res.json();
          setFollowingCount(data.length);
        } catch (error) {
          console.log({ "fetch following error": error });
        }
      };
      fetchFollowing(profileId);
    }
  }, [profileId]);

  // ------------------- CHAT ---------------------------------------------

  //get conversations
  const [conversations, setConversations] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [conversation_id, setConversationId] = useState(null);
  const [openChat, setOpenChat] = useState(false);

  const openChatWindow = () => {
    setOpenChat(!openChat);
  }

  useEffect(() => {
    if (userId) {
      const fetchConversations = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/slugcity/get/chat/conversations`,
            {
              method: "POST",
              body: JSON.stringify({ user_id: userId }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setConversations(data);
        } catch (error) {
          console.log({ "fetch conversations error": error });
        }
      };
      fetchConversations();
    }
  }, [userId]);

  // get messages

  useEffect(() => {
    if (conversation_id) {
      const fetchMessages = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/slugcity/get/chat/messages`,
            {
              method: "POST",
              body: JSON.stringify({
                user_id: userId,
                conversation_id: conversation_id,
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (!response.ok) {
            const errorData = await response.json();
            setChatMessages([]);
            console.log({ "fetch messages error": errorData });
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
          const data = await response.json();
          setChatMessages(data);
        } catch (error) {
          console.log({ "fetch messages error": error });
        }
      };
      fetchMessages();
    }
  }, [conversation_id]);

  useEffect(() => {
    socket.on("newMessage", (newMessage) => {
      setChatMessages((prevMessages) => [...prevMessages, newMessage]);
    });

    return () => {
      socket.off("newMessage");
    };
  }, []);

  //post message

  const postChatMessage = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcitypost/chat/message`,
        {
          method: "POST",
          body: JSON.stringify({
            user_id: userId,
            conversation_id: conversation_id,
            content: messageData,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setMessageData("");
    } catch (error) {
      console.log({ "post message error": error });
    }
  };


  // ----------------- FETCH USER BLOGS I FOLLOW ---------------------------

  const [followingBlogs, setFollowingBlogs] = useState([]);
  
  useEffect(() => {
    if (userId) {
      const fetchFollowingBlogs = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/slugcitypost/blogs/user-follow`,
            {
              method: "POST",
              body: JSON.stringify({ user_id: userId }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setFollowingBlogs(data);
        } catch (error) {
          console.error("Error fetching following blogs:", error);
        }
      };
      fetchFollowingBlogs();
    }
  }, [userId, token]);

  // ------------------  NOTIFICATION ----------------------------------------

  const [notifications, setNotifications] = useState([]);
  let goToNotificatin = window.location.pathname === "/user/notification";
  
  useEffect(() => {
    if (userId) {
      const fetchNotification = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/slugcity/get/notifications`,
            {
              method: "POST",
              body: JSON.stringify({ user_id: userId }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setNotifications(data);
        } catch (error) {
          console.error("Error fetching notification:", error);
        }
      };
      fetchNotification();
    }
  }, [userId, token,goToNotificatin]);

  useEffect(() => {
    if (userId) {
      socket.emit('joinRoom', userId); // Join a room specific to the user

      socket.on("followNotification", (notification) => {
        console.log({ "noti": notifications });
        setNotifications((prevNotification) => [notification, ...prevNotification]);
      });

      socket.on("blogLikeNotification", (notification) => {
        console.log({ "blognoti": notifications });
        setNotifications((prevNotification) => [notification, ...prevNotification]);
      });

      socket.on("commentLikeNotification", (notification) => {
        console.log({ "commentlikenoti": notifications });
        setNotifications((prevNotification) => [notification, ...prevNotification]);
      });

      return () => {
        socket.off("followNotification");
        socket.off("blogLikeNotification");
        socket.off("commentLikeNotification");
      };
    }
  }, [userId, notifications]);

  // ------------------  NOTIFICATION READ ----------------------------------------

  const readNotification = async ( notification_id, object_id) => {
    console.log("seeeeeennnnnn")
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/put/notifications-seen`,
        {
          method: "PUT",
          body: JSON.stringify({ user_id: userId, notification_id, object_id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setNotifications(prev => prev.filter(notification => notification.id !== notification_id));
    } catch (error) {
      console.error("Error reading notification:", error);
    }
  }

  //---------------- FETCH POST DETAIL ----------------------------------------
  
  const fetchUserBlogDetail = async (blog_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/get/userblogpost/${blog_id}`
      );
      const data = await response.json();
      setUserBlogDetail(data);
    } catch (error) {
      console.log(error);
    }
  };

  // ------------------- Rrepost/Delete/get reposts user blog ----------------------------------------
  const [reposts, setReposts] = useState([]);
  
  const repostUserBlog = async (e, originalPostId) => {
    e.preventDefault();
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/post/repost/blog`,
        {
          method: "POST",
          body: JSON.stringify({ user_id: userId, originalPostId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      
      
        setBlogs(prev => [data, ...prev]);
        setUserBlogs(prev => [data, ...prev]);
       setReload(!reload);
     
    } catch (error) {
      console.error("Error reposting blog:", error);
    }
  }

  const deleteRepost = async (e, originalPostId) => {
    console.log(originalPostId)
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/slugcity/delete/repost/blog`,
        {
          method: "DELETE",
          body: JSON.stringify({ user_id: userId, originalPostId }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setReload(!reload);
      setBlogs(prev => prev.filter(repost => repost.id !== originalPostId));
      setUserBlogs(prev => prev.filter(repost => repost.id !== originalPostId));
    } catch (error) {
      console.error("Error deleting repost:", error);
    }
  }

  useEffect(() => {
    if (userId) {
      const fetchReposts = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/slugcity/get/reposts`, {
            method: "POST",
            body: JSON.stringify({ user_id: userId }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },  
          })
          const data = await response.json();
          setReposts(data);
        }
        catch (error) {
          console.error("Error fetching reposts:", error);  
        } 
  
      }
      fetchReposts();
   }
  }, [userId, token,reload]);
 
  return (
    <div className="w-[100%] flex flex-col  ">
      <Navigation loggedIn={loggedIn} user={user} logoutUser={logoutUser} />
      {openChat ? (
        <Chat
        conversations={conversations}
        setConversationId={setConversationId}
        chatMessages={chatMessages}
        userId={userId}
        setMessageData={setMessageData}
        messageData={messageData}
          postChatMessage={postChatMessage}
          openChatWindow={openChatWindow}
        />
      ) : null}
     
      {loggedIn ? (
        <AddUserPostButton
          setOpenUserPostForm={setOpenUserPostForm}
          openUserPostForm={openUserPostForm}
        />
      ) : null}
      {openUserPostForm ? (
        <AddUserPostForm
          setOpenUserPostForm={setOpenUserPostForm}
          openUserPostForm={openUserPostForm}
          postUserBlog={postUserBlog}
          setUserPost={setUserPost}
          userPost={userPost}
        />
      ) : null}
      {openCommentsPost.open ? (
        <CommentsPost
          postComment={postComment}
          setOpenCommentsPost={setOpenCommentsPost}
          openCommentsPost={openCommentsPost}
        />
      ) : null}
      {openDeleteMsg ? (
        <AskForDelete
          openDeleteMsg={openDeleteMsg}
          setOpenDeleteMsg={setOpenDeleteMsg}
          deletePost={deletePost}
        />
      ) : null}
      {openCommentDeleteMsg ? (
        <DeleteComment
          openCommentDeleteMsg={openCommentDeleteMsg}
          setOpenCommentDeleteMsg={setOpenCommentDeleteMsg}
          deleteComment={deleteComment}
        />
      ) : null}

      <div className="flex w-full justify-center  ">
        <section className="sticky top-0 h-full ">
          <Menu
            logoutUser={logoutUser}
            loggedIn={loggedIn}
            user={user}
            openChatWindow={openChatWindow}
            notifications={notifications}
          />
        </section>
        <div id="container-page" className="xl:w-[36%] w-full 0 bg-mainColor">
          <Routes>
            <Route
              path="/"
              element={
                <StartPage
                  reload={reload}
                  userId={userId}
                  user={user}
                  deletePost={deletePost}
                  openDeleteMsg={openDeleteMsg}
                  setOpenDeleteMsg={setOpenDeleteMsg}
                  loggedIn={loggedIn}
                  logoutUser={logoutUser}
                  likeData={likeData}
                  addBlogLike={addBlogLike}
                  deleteBlogLike={deleteBlogLike}
                  userBlogs={userBlogs}
                  setOpenCommentsPost={setOpenCommentsPost}
                  bottomRef={bottomRef}
                  loading={loading}
                  hashtags={hashtags}
                  followingBlogs={followingBlogs}
                  repostUserBlog={repostUserBlog}
                  deleteRepost={deleteRepost}
                  reposts={reposts}
                />
              }
            />
            <Route
              path="/hashtag/search/:hashtag_name"
              element={
                <Search
                  searchHashtagPost={searchHashtagPost}
                  handleSearch={handleSearch}
                />
              }
            />
            <Route 
              path="/user/notification" 
              element={<Notification
                notifications={notifications}
                readNotification={readNotification}
              />}
            />
            <Route
              path="/chat/conversation"
              element={
                <Chat
                  conversations={conversations}
                  setConversationId={setConversationId}
                  chatMessages={chatMessages}
                  userId={userId}
                  setMessageData={setMessageData}
                  messageData={messageData}
                  postChatMessage={postChatMessage}
                />
              }
            />
            <Route path="/hash" element={<HashtagInput reload={reload} />} />
            <Route
              path="/comments/:comment_id"
              element={
                <CommentsDetail
                  fetchComment={fetchComment}
                  fetchChildComment={fetchChildComment}
                  user={user}
                  comment={comment}
                  childComment={childComment}
                  deleteComment={deleteComment}
                  logoutUser={logoutUser}
                  loggedIn={loggedIn}
                  reload={reload}
                  likeData={likeData}
                  postComment={postComment}
                  openCommentsPost={openCommentsPost}
                  setOpenCommentsPost={setOpenCommentsPost}
                  userId={userId}
                  openCommentDeleteMsg={openCommentDeleteMsg}
                  setOpenCommentDeleteMsg={setOpenCommentDeleteMsg}
                  addCommentLike={addCommentLike}
                  deleteCommentLike={deleteCommentLike}
                />
              }
            />
            <Route
              path="/userpostdetail/:blog_id"
              element={
                <UserBlogDetail
                  logoutUser={logoutUser}
                  loggedIn={loggedIn}
                  reload={reload}
                  postComment={postComment}
                  openCommentsPost={openCommentsPost}
                  setOpenCommentsPost={setOpenCommentsPost}
                  userId={userId}
                  deletePost={deletePost}
                  openDeleteMsg={openDeleteMsg}
                  setOpenDeleteMsg={setOpenDeleteMsg}
                  likeData={likeData}
                  addBlogLike={addBlogLike}
                  deleteBlogLike={deleteBlogLike}
                  deleteComment={deleteComment}
                  fetchBlogComments={fetchBlogComments}
                  commentData={commentData}
                  openCommentDeleteMsg={openCommentDeleteMsg}
                  setOpenCommentDeleteMsg={setOpenCommentDeleteMsg}
                  addCommentLike={addCommentLike}
                  deleteCommentLike={deleteCommentLike}
                  userBlogDetail={userBlogDetail}
                  setUserBlogDetail={setUserBlogDetail}
                  fetchUserBlogDetail={fetchUserBlogDetail}
                  repostUserBlog={repostUserBlog}
                  reposts={reposts}
                />
              }
            />
            <Route
              path="/:profileName"
              element={
                <UserProfile
                  followUser={followUser}
                  unfollowUser={unfollowUser}
                  followerData={followerData}
                  loggedIn={loggedIn}
                  logoutUser={logoutUser}
                  user={user}
                  setUser={setUser}
                  isFollowing={isFollowing}
                  setIsFollowing={setIsFollowing}
                  userId={userId}
                  deletePost={deletePost}
                  openDeleteMsg={openDeleteMsg}
                  setOpenDeleteMsg={setOpenDeleteMsg}
                  setOpenCommentsPost={setOpenCommentsPost}
                  token={token}
                  addBlogLike={addBlogLike}
                  deleteBlogLike={deleteBlogLike}
                  likeData={likeData}
                  setProfileId={setProfileId}
                  followerCount={followerCount}
                  followingCount={followingCount}
                  blogs={blogs} 
                  setBlogs={setBlogs}
                  repostUserBlog={repostUserBlog}
                  deleteRepost={deleteRepost}
                  reposts={reposts}
                />
              }
            />
            <Route
              path="/login"
              element={
                <Login
                  registerUser={registerUser}
                  loginUser={loginUser}
                  loginEmail={loginEmail}
                  setLoginEmail={setLoginEmail}
                  setLoginPassword={setLoginPassword}
                  username={username}
                  loginPassword={loginPassword}
                  setUsername={setUsername}
                />
              }
            />
          </Routes>
        </div>
        
        <Trending hashtags={hashtags} />
       
      </div>
      <div></div>
    </div>
  );
}

export default App;
