import React from 'react'
import { Link } from 'react-router-dom'

const Trending = ({fetchPopularHashtags,hashtags}) => {
  return (
    <section className='hidden bg-mainColor z-20  xl:block w-[300px] min-h-screen border-r-[1px] border-customBorderColor '>
      <div className='border bg-headerColor border-customBorderColor m-3 rounded-md p-4 sticky top-24'>
      <h3 className='text-xl font-medium mb-4'>Trending</h3>
      <ul className='px-2'>
        {hashtags && hashtags.map((hashtag) => (
          <li className='pb-2 flex items-center text-linkText hover:text-secTextColor' key={hashtag.id}>#<Link className='flex  items-center w-full' to={`/hashtag/search/${hashtag.hashtag}`} >{hashtag.hashtag}</Link> </li>
        ))}
      </ul>
      </div>
    </section>
  )
}

export default Trending