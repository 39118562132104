import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PostCard from "../PostCard/PostCard";
import { FaArrowLeft } from "react-icons/fa";
import UserProfileCard from "./UserProfileCard";
import Navigation from "../Navigation/Navigation";
import Trending from "../Navigation/Trending";
import EditUserProfile from "../Modals/EditUserProfile";
import io from 'socket.io-client';
import UserProfileCardBeta from "./UserProfileCardBeta";
const socket = io(process.env.REACT_APP_API_URL);

const UserProfile = ({
  deletePost,
  openDeleteMsg,
  deleteBlogLike,
  setOpenCommentsPost,
  likeData,
  addBlogLike,
  setOpenDeleteMsg,
  loggedIn,
  logoutUser,
  followerData,
  user,
  setUser,
  followUser,
  unfollowUser,
  isFollowing,
  setIsFollowing,
  userId,
  token,
  setProfileId,
  followerCount,
  followingCount,
  blogs,
  setBlogs,
  repostUserBlog,
  deleteRepost,
  reposts
}) => {
  const { profileName } = useParams();
  const [userProfileData, setUserProfileData] = useState(null);
  const [openProfileEdit, setOpenProfileEdit] = useState(false);

  // Set profileId in App component
  useEffect(() => {
    setProfileId(userProfileData && userProfileData.id);
  }, [userProfileData]);

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [profileName]);


  // GOING BACK ARROW FUNCTION
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };


  //-------- FETCH PROFILE USER DATA ------------

  useEffect(() => {
    const fetchProfileData = async () => {
      if (profileName) {
        try {
          const res = await fetch(`${process.env.REACT_APP_API_URL}/slugcity/get/users/profile/data`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username: profileName }),
          });
          const data = await res.json();
          setUserProfileData(data);
        } catch (error) {
          console.error("Error fetching profile data:", error);
        }
      }
    };
    fetchProfileData();
  }, [profileName]);

const [loading, setLoading] = useState(true);
const [page, setPage] = useState(1);

const bottom2Ref = useRef();


const fetchUserBlogs = useCallback(async () => {
  if (page) {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/slugcity/get/userblog/${profileName}?page=${page}`);
      const data = await res.json();
      setBlogs((prevBlogs) => {
        const newBlogs = data.filter(blog => !prevBlogs.some(prevBlog => prevBlog.id === blog.id));
        const combinedBlogs = [...prevBlogs, ...newBlogs];
        // Sort blogs by id in descending order
        combinedBlogs.sort((a, b) => b.id - a.id);
        return combinedBlogs;
      });
    } catch (error) {
      console.error("Error fetching user blogs:", error);
    } finally {
      setLoading(false);
    }
  }
}, [page, profileName]);

useEffect(() => {
  if (profileName) {
    fetchUserBlogs();
  }
}, [profileName, fetchUserBlogs]);

useEffect(() => {
  if (profileName) {
    setBlogs([]);
    setPage(1);
  }
}, [profileName]);

const observerCallback = useCallback((entries) => {
  if (entries[0].isIntersecting) {
    setPage((prevPage) => prevPage + 1);
  }
}, []);

useEffect(() => {
  const observer = new IntersectionObserver(observerCallback, { rootMargin: '60px', threshold: 1.0 });

  if (bottom2Ref.current) {
    observer.observe(bottom2Ref.current);
  }

  return () => {
    if (bottom2Ref.current) {
      observer.unobserve(bottom2Ref.current);
    }
  };
}, [observerCallback]);

useEffect(() => {
  if (profileName) {
    setBlogs([]);
    setPage(1);
  }
}, [profileName]);
  
    //like section socket.io
    const handleLikeUpdated = ({ blog_id, like_count }) => {
      console.log('Received likeUpdated event for blog_id:', blog_id, 'with like_count:', like_count);
      setBlogs((prevBlogs) =>
        prevBlogs.map((blog) =>
          blog.id === blog_id ? { ...blog, like_count: like_count } : blog
        )
      );
    };
  
    useEffect(() => {
      socket.on('likeUpdated', handleLikeUpdated);
  
      return () => {
        socket.off('likeUpdated', handleLikeUpdated);
      };
    }, []);

  return (
    <div className='w-full flex justify-center min-h-screen ' >
      
      {openProfileEdit ?<EditUserProfile  token={token} openProfileEdit={openProfileEdit} setOpenProfileEdit={setOpenProfileEdit} userId={userId} user={user} setUser={setUser} setUserProfileData={setUserProfileData} userProfileData={userProfileData}/> : null}
     
      <section className='xl:w-[100%] w-full xl:border-l-[1px] z-10  xl:border-r-[1px] border-customBorderColor min-h-full '>
        <div className="bg-mainColor flex items-center gap-3 sticky top-[50px] p-1 border-b-[1px] z-10 border-customBorderColor">
          <span onClick={ handleBackClick } className="hover:bg-white/5 p-3"><FaArrowLeft/></span>
          <span className="text-lg ">{userProfileData && userProfileData.username}</span>
        </div>

        <UserProfileCard
                  userProfileData={userProfileData}
                  followerData={followerData}
          user={user}
          setUser={setUser}
                  followUser={followUser}
                  unfollowUser={unfollowUser}
                  isFollowing={isFollowing}
                  setIsFollowing={setIsFollowing}
                  setOpenProfileEdit={setOpenProfileEdit}
          userId={userId}
          followerCount={followerCount}
          followingCount={followingCount}
        />
        {blogs && blogs.map((blog, index) => (
          <PostCard
          key={blog.id}
          blog={blog}
          userId={userId}
          deletePost={deletePost}
          openDeleteMsg={openDeleteMsg}
          setOpenDeleteMsg={setOpenDeleteMsg}
          likeData={likeData}
          addBlogLike={addBlogLike}
          deleteBlogLike={deleteBlogLike}
            setOpenCommentsPost={setOpenCommentsPost}
            loggedIn={loggedIn}
            repostUserBlog={repostUserBlog}
            deleteRepost={deleteRepost}
            reposts={reposts}
          />
        ))}
          {loading && <div>Loading...</div>}
          <div ref={bottom2Ref} style={{ height: '20px' }}></div>
      </section>
      
</div>
  );
};

export default UserProfile;
