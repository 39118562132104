import React from 'react'
import { FaPen } from "react-icons/fa";

const AddUserPostButton = ({openUserPostForm,setOpenUserPostForm}) => {
  return (
    <div onClick={() => setOpenUserPostForm(!openUserPostForm)} className='flex justify-center items-center rounded-full w-14 h-14 bg-elementColor  text-white text-5xl fixed xl:left-10 xl:bottom-8 left-4 bottom-4 cursor-pointer z-50'>
      <FaPen size={ 28} />
    </div>
  )
}

export default AddUserPostButton