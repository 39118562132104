import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { IoIosHeartEmpty, IoMdHeart } from "react-icons/io";
import { VscComment } from "react-icons/vsc";
import { MdDelete } from "react-icons/md";

const Comments = ({ comment,
  userId,
  index,
  deleteComment,
  setOpenCommentsPost,
  postComment,
  setOpenCommentDeleteMsg,
  openCommentDeleteMsg,
  addCommentLike,
  deleteCommentLike,
  likeData,
  loggedIn
}) => {

  const isLiked = likeData && likeData.find((like) => like.comment_id === comment.comment_id );
 
  return (
    <Link to={`/comments/${comment.comment_id}`}>
       <div
      key={index}
      className="flex gap-4 w-[100%] mt-0 border-b-[1px] border-customBorderColor  p-4 ">
      <section className="w-full">
        <div className="w-full flex items-center justify-between gap-1 mb-4">
            <div className="flex gap-1 items-center">
            <section className=' w-14 h-14 '>
        <img src={`${process.env.REACT_APP_API_URL}/images/${comment && comment.avater}`} className='bg-neutral-950 rounded-full w-12 h-12 object-cover'/>
      </section>
          <p className=" font-semibold flex justify-between">
            <Link
              to={`/${comment && comment.username}`}
              className="hover:text-elementColor "
            >
              {comment && comment.username}
            </Link>
          </p>
          <p className="text-sm text-secTextColor">
            {moment(comment && comment.created_at).fromNow()}
          </p>
    </div>
            <div>
            {comment.user_id === userId && (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                    setOpenCommentDeleteMsg({ status: true, id: comment.comment_id });
                  }}
                  className='text-elementColor text-lg p-1 w-fit border border-customBorderColor flex z-30 hover:bg-black/20'
                >
                  <MdDelete />
                </button>
              )}
            </div>
          </div>
          
        <div className="my-2 px-2 text-base text-secTextColor whitespace-pre-wrap">
          {comment && comment.content}
        </div>
          {loggedIn ? (
                      <div className="mt-2 px-2 text-xl flex gap-2">
                      <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenCommentsPost(prevState => ({ ...prevState, open: !prevState.open, commentType:"parent_comment_id", id:comment.comment_id }));
                      }}
                        className="flex items-center text-lg"><VscComment />{comment && comment.child_comment_ids.length}</div>
                  <div className='flex gap-1 items-center'>
                      {isLiked ?
                        <span onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                          deleteCommentLike(e, comment.comment_id);
                        }} className='text-elementColor'><IoMdHeart />
                        </span>
                        :
                        <span onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                          addCommentLike(e, comment.comment_id);
                        }}><IoIosHeartEmpty /></span>
                      }
                      <span> {comment.like_count}</span>
                    </div>
                  </div>
          ):(
            <div className="mt-2 px-2 text-xl flex gap-2">
            <div onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              
            }}
              className="flex items-center text-lg"><VscComment />{comment && comment.child_comment_ids.length}</div>
        <div className='flex gap-1 items-center'>
            {isLiked ?
              <span onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                
              }} className='text-elementColor'><IoMdHeart />
              </span>
              :
              <span onClick={(e) => {
                e.preventDefault();
                e.stopPropagation(); // Prevent the click event from bubbling up to the Link
                
              }}><IoIosHeartEmpty /></span>
            }
            <span> {comment.like_count}</span>
          </div>
        </div> 
)}
      </section>
      </div>
    </Link>
  );
};

export default Comments;
