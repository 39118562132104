import React from 'react'

const DeleteComment = ({openCommentDeleteMsg, setOpenCommentDeleteMsg,deleteComment}) => {
  return (
    <div  className='flex modal-background items-center justify-center w-full z-50 h-screen  backdrop-blur-sm fixed'>
    <form onClick={(e) => e.stopPropagation()} className='flex flex-col gap-4 items-center justify-center xl:w-[650px] w-full xl:h-[400px] h-full xl:p-10 px-3 bg-headerColor rounded-md' action="">
              <p className='font-semibold text-lg'>delete your comment?</p>
              <button onClick={(e)=> deleteComment(e, openCommentDeleteMsg.id)} className='text-elementColor font-semibold border border-neutral-500 rounded-full px-8 py-2 hover:bg-elementColor hover:text-white'>Delete</button>
              <button onClick={() => setOpenCommentDeleteMsg(false) } className='font-semibold border border-neutral-500 rounded-full px-8 py-2 hover:bg-black/20'>Cancle</button>
    </form>
  </div>
  )
}

export default DeleteComment