import React, { useState } from 'react';

export const LoginForm = ({ loginUser, setLoginEmail, setLoginPassword, loginEmail, loginPassword }) => {
    return (
        <div className='flex flex-col p-8'>
            <h2 className='text-xl font-medium mx-auto my-4'>Please Login</h2>
            <input type="email" placeholder="email" value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} />
            <input type="password" placeholder="password" value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} />
            <button onClick={ loginUser } className='w-full bg-elementColor text-white py-3 mt-1'>login</button>
        </div>
    );
};

export const RegisterForm = ({ registerUser, username, setUsername, loginEmail, setLoginEmail, loginPassword, setLoginPassword }) => {
    return (
        <div className='flex flex-col p-8'>
            <h2 className='text-xl font-medium mx-auto my-4'>Please Register</h2>
            <input 
                name="username"
                type="text" 
                placeholder="username"
                value={username} 
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                name="email"
                type="email"
                placeholder="email"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
            />
            <input
                name="password"
                type="password"
                placeholder="password"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
            />
            <button onClick={registerUser} className='w-full bg-elementColor text-white py-3 mt-1'>register</button>
        </div>
    );
};

const Login = ({ loginUser, registerUser,username, setUsername,loginEmail, setLoginEmail,loginPassword, setLoginPassword }) => {

    const [isLoginForm, setIsLoginForm] = useState(true);

    const showLoginForm = () => {
        setUsername("");
        setLoginEmail("");
        setLoginPassword("");
        setIsLoginForm(true);
    };
    
    const showRegisterForm = () => {
        setUsername("");
        setLoginEmail("");
        setLoginPassword("");
        setIsLoginForm(false);
    };

    const content = isLoginForm ? 
        <LoginForm loginUser={loginUser} setLoginEmail={setLoginEmail} setLoginPassword={setLoginPassword} loginEmail={loginEmail} loginPassword={loginPassword} />
        :
        <RegisterForm registerUser={registerUser} username={username} setUsername={setUsername} loginEmail={loginEmail}  setLoginEmail={setLoginEmail} loginPassword={loginPassword} setLoginPassword={setLoginPassword} />;

    return (
        <div className='w-full h-full flex items-center mt-0  border-customBorderColor border-l-[1px] border-r-[1px]'>
            <section className='flex flex-col bg-mainColor h-fit xl:h-full   w-full  px-5  mx-auto'>
                <div className='flex my-4 gap-1'>
                    <button onClick={showLoginForm} className='hover:bg-menuHover  border-customBorderColor border  w-[50%] py-3 text-lg font-semibold '>login</button>
                    <button onClick={showRegisterForm} className="hover:bg-menuHover  border-customBorderColor border w-[50%] py-3 text-lg font-semibold ">register</button>
                </div>
                <section className='border border-customBorderColor h-[450px] mb-4'>
                    <form action="">
                        {content}
                    </form>
                </section>
            </section>
        </div>
    );
}

export default Login;